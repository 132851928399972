import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { DeveloperConstants } from "./developer-constants";
@Injectable()
export class DeveloperModeHelper {
  public isDeveloper: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDeveloperModeEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //JSON.parse(localStorage.getItem(DeveloperConstants.IS_DEVELOPER_MODE)));
  constructor() {
    // _auth.mgr.events.addUserLoaded((e) => {
    //   if(_auth.currentUser.profile && _auth.currentUser.profile.role) {
    //     
    //     if(_auth.currentUser.profile.role === 'Developer') {
    //       console.log(`Developer Mode: Set to ${true}`);
    //       this.isDeveloper.next(true);
    //     }
    //   }
    // });
    // let permissions = JSON.parse(localStorage.getItem("permissions"));
    // 
    // if(permissions) {
    // var foundRole = permissions.find(_=>_ === "Developer");
    // if(foundRole) {
    //   this.isDeveloper.next(true);
    // }
    // }
  }
  next(val: boolean) {
    localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(val));
    this.isDeveloperModeEnabled.next(val);
  }
}
