import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Profile } from "../models/profile.model";
import { ApiService } from "./api.service";



@Injectable()
export class ProfileService {

  constructor(
    private _apiService: ApiService
  ) { }

  public getProfileByIdQuery(identifier: string,): Observable<Profile> {
    return this._apiService.get<Profile>(`profile/id/${identifier}`);
  }

  public getProfileBySubjectIdQuery(identifier: string): Observable<Profile> {
    return this._apiService.get<Profile>(`profile/subjectId/${identifier}`);
  }

  public createProfile<T>(object: T): Observable<T> {
    
    return this._apiService.postWithoutRequestMessage('profile/create', object);
  }

  public updateProfile(profile: Profile): Observable<Profile> {
    return this._apiService.postWithoutRequestMessage<Profile, Profile>('profile/update', profile);
  }
}