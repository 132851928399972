import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class SharedTitleService {

  // Observable string sources
  private titleAnnouncedSource = new Subject<string>();
  private breadcrumbsAnnoucedSource = new Subject<Breadcrumbs>();
  //private missionConfirmedSource = new Subject<string>();

  // Observable string streams
  titleAnnounced$ = this.titleAnnouncedSource.asObservable();
  breadcrumbsAnnounced$ = this.breadcrumbsAnnoucedSource.asObservable();
 
  // Service message commands
  announceTitleChange(title: string) {
    this.titleAnnouncedSource.next(title);
  }

  announceBreadcrumbs(breadcrumbs: Breadcrumbs) {
    this.breadcrumbsAnnoucedSource.next(breadcrumbs);
  }
}

export class Breadcrumbs {
  constructor(
    public name: string,
    public url: string
  ) { }
}